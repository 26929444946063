<template>
  <div>
    <div v-if="$store.getters['auth/isAuthenticated']" class="ytm-container">
      <NavbarAuthorized/>
    </div>
    <div v-else class="ytm-landing-wrapper">
      <NavbarUnauthorized/>
    </div>
    <div class="ytm-container" style="margin-top: 2.25rem; margin-bottom: 10rem">
      <p class="ytm-default-text ytm-head-text" style="margin-bottom: 2rem">
        Как эффективно использовать YouTeachMe на&nbspуроках и&nbspне&nbspтолько
      </p>
      <p class="ytm-default-text ytm-section">
        Привет! Это краткое руководство по использованию <b>YouTeachMe</b> – лучшего помощника онлайн-репетитора.
      </p>
      <div class="ytm-tutorial-block">
        <p class="ytm-default-text ytm-section">
          <b>В первом видео</b> мы рассказываем о том, как создать курс, пригласить в него учеников и создать первое занятие,
          а также как пользоваться чатом и расписанием:
        </p>
        <div class="ytm-section" style="margin: 2rem 0">
          <div class="ytm-auto-resizable-iframe">
            <div>
              <iframe
                  src="https://www.youtube.com/embed/v2A5TJhHhdU"
                  title="Создание курса на YouTeachMe"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
              />
            </div>
          </div>
        </div>
        <p class="ytm-default-text ytm-section">
          Уже готовы создать <b>курс на YouTeachMe?</b>
        </p>
        <div class="ytm-section" style="display: flex; justify-content: center">
          <div style="display: flex">
            <a
                class="ytm-blue-button ytm-default-text"
                href="/courses"
                target="_blank" rel="noopener noreferrer"
                style="color: #FFFFFF; font-size: 1.15rem; font-weight: 600"
            >
              К курсам!
            </a>
          </div>
        </div>
      </div>
        <div class="ytm-tutorial-block">
            <p class="ytm-default-text ytm-section">
                Уже создали первый курс и запланировали в нём первый урок?
            </p>
            <p class="ytm-default-text ytm-section">
                <b>В данном видео</b> мы показываем, как использовать материалы
                YouTeachMe во время урока – онлайн-доску, совместно редактируемый текст, тесты и другие типы материалов:
            </p>
            <div class="ytm-section" style="margin: 2rem 0">
                <div class="ytm-auto-resizable-iframe">
                    <div>
                        <iframe
                                src="https://www.youtube.com/embed/Eanx3DP5Oog"
                                title="Создание курса на YouTeachMe"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                        />
                    </div>
                </div>
            </div>
            <p class="ytm-default-text ytm-section">
                Хотите попробовать <b>материалы YouTeachMe</b> в деле?
            </p>
            <div class="ytm-section" style="display: flex; justify-content: center">
                <div style="display: flex">
                    <a
                            class="ytm-blue-button ytm-default-text"
                            href="/home"
                            target="_blank" rel="noopener noreferrer"
                            style="color: #FFFFFF; font-size: 1.15rem; font-weight: 600"
                    >
                        К расписанию!
                    </a>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import "@/styles/style.css";
import "@/components/LandingPage/landing.css";
import NavbarAuthorized from "@/components/redesign/Navbar";
import NavbarUnauthorized from "@/components/LandingPage/Navbar";

export default {
  name: 'Tutorial',
  components: {NavbarAuthorized, NavbarUnauthorized},
};
</script>

<style scoped>
.ytm-tutorial-block {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 1.5rem;
    padding: 2rem 2rem 1rem 2rem;
}

.ytm-section {
  margin-bottom: 1.5rem;
  font-size: 1.15rem;
}

.ytm-auto-resizable-iframe {
  max-width: 720px;
  margin: 0 auto;
}

.ytm-auto-resizable-iframe > div {
  position: relative;
  padding-bottom: 60%;
  height: 0;
}

.ytm-auto-resizable-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>